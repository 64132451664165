var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.currentUser.modul_id !== 5)?_c('header-slot'):_vm._e(),_c('b-nav',{staticClass:"m-0",attrs:{"card-header":"","pills":""}},[_c('b-nav-item',{attrs:{"to":{ name: _vm.$route.meta.pendingRoute },"exact":"","exact-active-class":"active","link-classes":[
        _vm.currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
        'px-3',
        _vm.bgTabsNavs ]}},[_vm._v("Pending")]),_c('b-nav-item',{attrs:{"to":{ name: _vm.$route.meta.returnedRoute },"exact":"","exact-active-class":"active","link-classes":[
        _vm.currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
        'px-3',
        _vm.bgTabsNavs ]}},[_vm._v(" Returned "),(_vm.countData > 0 && _vm.currentUser.role_id != 1)?_c('span',{staticClass:"ml-2"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.countData,"badge-classes":"badge-important"}})],1):_vm._e()]),_c('b-nav-item',{attrs:{"to":{ name: _vm.$route.meta.completedRoute },"exact":"","exact-active-class":"active","link-classes":[
        _vm.currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
        'px-3',
        _vm.bgTabsNavs ]}},[_vm._v("Completed")])],1),_c('b-card',{staticClass:"border-3 border-table-radius",class:_vm.currentUser.modul_id !== 5 ? 'border-top-primary' : 'border-top-info',attrs:{"no-body":""}},[_c('router-view',{key:_vm.$route.name})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }